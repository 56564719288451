
/**
 * 質問と回答
 * ※後でデータ化する
 */

/**
 * 質問の配列
 * ※今後はDBに移行する可能性あり
 */

// 選択肢の配列
const commonOptions = [
    { id: 1, text: 'YES' },
    { id: 2, text: 'どちらかというとYES' },
    { id: 3, text: 'どちらでもない' },
    { id: 4, text: 'どちらかというとNO' },
    { id: 5, text: 'NO' },
];

// 質問の配列
const questions = [
    "泣いたり怒ったり気分の浮き沈みが激しい方だ",
    "何かに集中すると周りが見えなくなる",
    "健康な体づくりにとても興味がある",
    "相手に合わせて物事をわかりやすく伝えるのが苦手だ",
    "人と話すことが好きだ",
    "何かをするときに計画を立てるより、まず行動する",
    "自分の話でみんなを注目させたり、その場をまとめたりすることができる",
    "今話題になっていることや新しい流行には、人よりも敏感だと思う",
    "細かい数字を見たり、細かいことを考えたりすることは苦手だ",
    "基本的に嘘や誤魔化しができない性格だ",
    "自分だけのオリジナルな世界を表現することが大好きだ",
    "突然のハプニングが起こった時慌ててしまう方だ",
    "冗談を言ったりして場を盛り上げることが好きだ",
    "何か失敗しても、すぐに気を取り直すことができす",
    "やると決めたらすぐに行動に移すことができる",
    "ボンヤリ頭に浮かんだことは、具体的な形や考えにすることができない",
    "他の人と同じやり方で物事を進めるのは好きではない",
    "自分の考えに合わない相手に合わせるのは苦手だ",
    "いつだって自分の実力や才能を大勢の人に認めてもらいたい",
    "物事を順序よく筋道を立てて考えるのが苦手だ",
    "人の話をしっかり理解して、自分の気持ちをうまく伝えることができない",
    "栄養バランスやカロリーを考えた食事をあまりしない",
    "ちょっとしたことは、いい加減にしてしまう",
    "自分の考えや気持ちをうまく伝えられず、もどかしく思うことがよくある",
    "一人でいるよりも沢山の人といる方が楽しい",
    "みんなで決めたことより、自分の考えを貫くことが大切だ",
    "ある程度満足しても、それ以上を目指す"
];

/**
 * 実際にフロントに渡す形に成形
 */
const questionsData = [];

let index = 1;

questions.forEach(question => {

    questionsData.push({
        id: index,
        text: question,
        options: commonOptions,
    });

    // indexをインクリメント
    index++;
});

module.exports = questionsData;

