import React from 'react';

const GoBackButton = () => {

    // ブラウザの戻る操作を実行
    const handleGoBack = () => {
        window.history.back();
    };

    return (
        <div className="text-right mt-2">
        <button 
            className="bg-yellow-300 py-1 px-4 rounded-full"
            onClick={handleGoBack}>
                戻る
        </button>
        </div>
    );
};

export default GoBackButton;
