import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './style/App.scss';
// pages
import Start from './pages/Start'; // 診断開始ページ
import Question from './pages/Question'; // 質問ページ 
import ResultConfirm from './pages/ResultConfirm'; // 診断結果確認ページ
import Result from './pages/Result'; // 診断結果ページ
import NextStep from './pages/NextStep'; // Line追加ページ


function App() {
  return (
    <Router>
      <Routes>

        {/* ルーティングを定義 */}

        {/* スタートページ */}
        <Route path="/" element={<Start />} />

        {/* 質問ページ */}
        <Route path="/question/:id" element={<Question />} />

        {/* 診断結果確認ページ */}
        <Route path="/question/result_confirm" element={<ResultConfirm />} />

        {/* 診断結果ページ */}
        <Route path="/question/result" element={<Result />} />

        {/* Line追加ページ */}
        <Route path="/next_step" element={<NextStep />} />

      </Routes>
    </Router>
  );
}

export default App;