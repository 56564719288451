import React from 'react'
import LinkButton from '../components/linkButton'

export default function NextStep() {

    return (
        <div className='flex h-screen justify-center items-center bg-start'>

            {/* 画像を配置 */}
            <div className="relative h-full">

                <img className='h-full' src="/images/S__31449125.jpg" alt="NextStepページの背景です" />

            </div>

            {/* リンクボタン */}
            <LinkButton 
            to="https://page.line.me/008ylpsn" 
            className='absolute next-step-button-container w-42' 
            LinkFoClassName='bg-red-200 text-white next-step-button'
            >

                Line友達追加

            </LinkButton>

        </div>
    )

}
