import React from 'react'
import LinkButton from '../components/linkButton';

const Start = () => {
    return (

        <div className='flex h-screen justify-center items-center bg-start'>

            {/* 画像を配置 */}
            <div className="start-img-area h-full">

                <img className='h-full p-3' src="/images/S__31449123.jpg" alt="スタートページの背景です" />

            </div>

            {/* 一問目に進む */}

            {/**
             * リンク用ボタン
             * to = リンク先
             * className = buttonをラップするクラス名
             * LinkFoClassName　= Linkのクラス名
            */}
            <LinkButton 
            to="/question/1" 
            className='absolute start-button-container' 
            LinkFoClassName='bg-red-200 text-white p-4 start_button'
            >

                診断を開始する

            </LinkButton>

        </div>

    )
}

export default Start
