
// アクションを定義
export const setSelectedOptions = (options) => ({
    type: 'SET_SELECTED_OPTIONS',
    payload: options,
});

export const setQuestionData = (data) => ({
    type: 'SET_QUESTION_DATA',
    payload: data,
});
