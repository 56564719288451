import React from 'react';

// メインのスタイルコンポーネント
const MainStyle = ({ children }) => {
    return (
        <div className="bg-mian flex min-h-screen justify-center items-center">
            <div className="bg-inner min-h-90 rounded-xl p-4 w-90">
                {children}
            </div>
        </div>
    );
}

// 高さが100vhをオーバーする場合のフォールバックコンポーネント
const FullHeightFallback = ({ children }) => {
    return (
        <div className="bg-mian flex h-full py-4 justify-center items-center">
            <div className="bg-inner h-full rounded-xl p-4 w-90">
                {children}
            </div>
        </div>
    );
}

export { MainStyle, FullHeightFallback };
