import React from 'react';
import { Link } from 'react-router-dom';


// リンクを作るときのボタン
// classNameは呼び出しもとで管理
const LinkButton = ({ className, LinkFoClassName, to, children }) => {

return (

    <div className={className}>

    <Link to={to} className={LinkFoClassName}>

        {children}

    </Link>

    </div>
    
);
}

export default LinkButton;
