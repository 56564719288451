
/**
 * 診断結果
 * ※後でデータ化する
 */

// 診断結果
const questionResult = {
    1:{
        title: "チャレンジャータイプ",
        content: "自分にしっかりとした自信があり、積極的に人を引っ張って動くことができるタイプです。困難に立ち向かい乗り越えることが好きで、なにかトラブルが起きても自分の力で解決しようと努力することができます。また、強く頼もしい人間でありたいという思いが強く、自分の力で弱い人を助け導くことに喜びを感じます。一方で、自分の考えがはっきりしていているため、人に対して自己中心的な振る舞いをしてしまったり、命令口調が強くなってしまうことがあります。挑戦心とリーダーシップに優れた性格なので、営業職やコンサルタントの仕事が向いています。"
    },
    2:{
        title: "ポジティブ思考タイプ",
        content: "人生を明るく楽しく過ごすことをとても大事にするタイプです。楽観的で好奇心旺盛な一面があり、楽しそうなことがあれば積極的に参加します。自分でイベントごとを考えたり準備・企画したりすることも大好きで、いつでも周囲の人を巻き込んで盛り上がることができます。一方で、楽しさに気を取られて目移りすることが多いために、落ち着きが無いように見られてしまうこともあります。企画を立てたり人を巻き込んだりすることが得意な性格なので、営業やコンサルティングの仕事が向いています。初めて話す人とでも明るく前向きに会話ができるので、人と話す仕事に適性があります。"
    },
    3:{
        title: "実績重視タイプ",
        content: "自分の掲げた目標を達成することに一生懸命なタイプです。成功・失敗の意識が強く、効率良く合理的に物事を進めることや、人から評価されることを重視して動く傾向があります。一度目標を見定めれば、周囲も巻き込みながら意欲的に動くことができます。一方で、結果ばかりに目が向いてしまい経緯や過程を軽視しがちなので、周囲からは横柄な性格だと思われてしまうこともあります。目標達成に向けて努力を惜しまない性格なので、営業やコンサルティングの仕事が向いています。"
    },
    4:{
        title: "完璧主義タイプ",
        content: "何事においても完璧を目指し、自分の考える高い理想に向かって努力を惜しまないタイプです。責任感が強く几帳面で、妥協や不正を許すことができません。また、規則やマニュアルが用意されている状況で働くことを得意とし、細かいことまできっちり守ろうと心がけます。一方で、正しさにこだわるあまり他人の不真面目さが気になってしまうことが多く、周囲からは頑固で融通がきかない人と思われてしまうことがあります。ルールを守り責任をもって仕事ができるので、コンサルティングに関わる仕事や管理をする仕事が向いています。"
    },
    5:{
        title: "世話焼きタイプ",
        content: "人に優しく心が広い穏やかなタイプです。争いごとが苦手で、揉めごとがあってもなるべく平穏に円満な解決を図ろうとします。また、気遣い上手で相手の気持ちに寄り添って考えることができるので、誰とでも良好な人間関係を保つことができます。一方で、人の目を気にするあまり自分の意見を主張することが苦手な傾向があり、人の意見に合わせすぎてしまうことが多いです。職場でも周囲に気を配り世話を焼くことが得意なので、総務や人事の仕事が向いています。また、人に優しく穏やかに接することができるので、人材紹介の仕事にも適性があります。"
    },
};

module.exports = questionResult;

