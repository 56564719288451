// 初期状態を定義
const initialState = {
    selectedOptions: [],   // 選択されたオプションを格納するステート
    questionData: [],      // 質問データを格納するステート
};

// ルートReducer関数
const rootReducer = (state = initialState, action) => {

    switch (action.type) {

    // 'SET_SELECTED_OPTIONS'アクションの処理
    case 'SET_SELECTED_OPTIONS':
        return {
            ...state,
            selectedOptions: action.payload, // 選択されたオプションを更新
        };

    // 'SET_QUESTION_DATA'アクションの処理
    case 'SET_QUESTION_DATA':
        return {
            ...state,
            questionData: action.payload,    // 質問データを更新
        };

    // デフォルトのアクションは現在のステートをそのまま返す
    default:
        return state;
    }

};

export default rootReducer;
