/*
回答結果から診断結果を取得する関数
@param Object
@return Object
*/
function resultJudgment(selectedOptions) {
    
    // 選択肢ごとのカウントを格納するオブジェクト
    const optionCount = {};

    // selectedOptionsをループしてoptionIdを数える
    selectedOptions.forEach(option => {
        const { optionId } = option;

        // optionIdがまだカウントされていない場合、初期値をセット
        if (!optionCount[optionId]) {
            optionCount[optionId] = 1;
        } else {
            // すでにカウントされている場合はインクリメント
            optionCount[optionId]++;
        }
    });

    // カウントされたデータを確認
    console.log(optionCount);

    // 最もカウントが多いoptionIdを見つける
    let maxOptionId = null;
    let maxCount = 0;

    for (const optionId in optionCount) {
        if (optionCount[optionId] > maxCount) {
            maxCount = optionCount[optionId];
            maxOptionId = optionId;
        }
    }

    // 結果を返す
    return maxOptionId;
}

// 関数をエクスポート
module.exports = resultJudgment;