import React, { useEffect, useState } from 'react';
import { useLocation, Link  } from 'react-router-dom';
// components
import {FullHeightFallback} from '../components/MainStyle';
import LinkButton from '../components/linkButton';
// const
import questionResultData from '../const/questionResult';
// function
import resultJudgment from '../functions/commonFunctions';

const Result = () => {

    // 前ページから渡されたステートを受け取る
    const location = useLocation();

    // 前ページからのステート受け取り
    const { state } = location;

    // 回答結果取得
    const selectedOptions = state.selectedOptions;

    // ステート: 診断結果
    const [questionResult, setQuestionResult] = useState([]);

    const fetchQuestionResult = async () => {
        try {

            // 診断結果を取得(バックエンドに回答を渡す)
            const response = resultJudgment(selectedOptions);
            
            // 診断結果取得
            const resultData = questionResultData[response]; // index番号が異なるため-1で処理する

            // 取得した診断結果ををステートに設定
            setQuestionResult(resultData);

        } catch (error) {

            console.error('診断結果の取得に失敗しました', error);

        }
    };

    console.log(process.env.PUBLIC_URL);
    useEffect(() => {

        // fetchQuestionResult関数を呼び出す
        fetchQuestionResult();

    }, []);
    
    return (
        <FullHeightFallback>

            <div>
            
                {/* タイトル */}
                <h1 className="text-center">診断結果</h1>

                <div className="text-center">あなたのタイプは...</div>

                {/* 診断結果タイトル */}
                <div className="text-center text-xl py-2">{questionResult.title}</div>
                
                {/* 画像 */}
                <img src={process.env.PUBLIC_URL + '/images/result1.png'} alt="Description" />

                {/* 診断結果コメント */}
                <div>{questionResult.content}</div>

                {/* リンクボタン */}
                <LinkButton 
                to="/next_step" 
                className='result-button-container mt-5 mx-auto w-56' 
                LinkFoClassName='bg-red-200 text-white result_button'
                >

                    次のステップ

                </LinkButton>
                


            </div>

        </FullHeightFallback>

    );
};

export default Result;
