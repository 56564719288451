import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
// Components
import { MainStyle } from '../components/MainStyle';
import QuestionOption from '../components/QuestionOption';
import ProgressIndicator from '../components/ProgressIndicator';
import GoBackButton from '../components/ReturnButton';
import axios from 'axios';
// Redux
import { connect } from 'react-redux';
import { setSelectedOptions, setQuestionData } from '../actions/action';
// const
import questionsData from '../const/questions';

const Question = ({
    selectedOptions,
    setSelectedOptions,
    setQuestionData,
    questionData,
    }) => {

    // idを取得
    const { id } = useParams();

    // useNavigateオブジェクトを取得
    const navigate = useNavigate();

    // ステート: 質問の件数
    const [dataCount, setDataCount] = useState(null);

    // ステート: 最後の質問が選択されたか否か
    const [isLastQuestion, setIsLastQuestion] = useState(false);

    // idが更新されたときに発火
    useEffect(() => {

        // ページ遷移時に質問データを取得
        const fetchQuestion = async () => {

            try {

                // 取得したデータ総数をステートに設定
                setDataCount(questionsData.length);

                // 取得した質問データをステートに設定
                setQuestionData(questionsData[id - 1]); // データ配列のindex番号は０からのため、-1で処理する

            } catch (error) {

                console.error('質問データの取得に失敗しました', error);
                
            }

        };

        // 関数を呼び出す
        fetchQuestion();

    }, [id, setQuestionData]);


    // 選択肢のボタンクリック時の処理
    const handleOptionSelect = (optionId) => {

        // 新しい選択肢オブジェクトを作成
        const newOption = { id, optionId };
        
        // ステートに新しい選択肢を追加
        setSelectedOptions([...selectedOptions, newOption]);

        // 遅延させる秒数を定義
        const delayTime = 500;

        // 次のページのidを取得
        const nextQuestionId = Number(id) + 1;

        if (nextQuestionId <= dataCount) {

            // 次のページが存在する場合、遅延後にページ遷移
            setTimeout(() => {
                navigate(`/question/${nextQuestionId}`);
            }, delayTime);
            
        } else {

            // 最後の選択肢の場合、診断結果確認ページへ遷移
            setTimeout(() => {

                // 最後の質問の場合、確認ボタンのみを表示
                setIsLastQuestion(true);

            }, delayTime);
        }
    };

    // 確認ボタンがクリックされたときの処理
    const handleConfirmButtonClick = () => {

        // 確認ページに遷移する処理を実行
        navigate("/question/result", { state: {selectedOptions} });
        
    };

    return (
        <MainStyle>
            <>

            {/* 質問を表示 */}
            {!isLastQuestion && (
                <>
                    {/* 質問数 */}
                    <h1 className="titleBorder text-center text-3xl my-8">Q{questionData.id}</h1>

                    {/* 質問内容 */}
                    <h1 className="text-center text-2xl my-16 font-semibold">{questionData.text}</h1>

                    {/* 選択肢のコンポーネント */}
                    <div className="flex flex-row justify-center">
                        {questionData.options &&
                            questionData.options.map((option) => (
                                <QuestionOption
                                    key={option.id}
                                    option={option}
                                    selected={selectedOptions}
                                    handleOptionSelect={handleOptionSelect}
                                />
                            ))
                        }
                    </div>

                    {/* 進捗状況のバー */}
                    <div>
                        <ProgressIndicator 
                            totalQuestions={dataCount ? dataCount : 0} 
                            currentQuestion={id - 1} 
                        />
                    </div>

                    {/* 戻るボタン */}
                    <GoBackButton />
                </>

            )}


            {/* 確認ボタンのみを表示（最後の質問が選択されたとき） */}
            {isLastQuestion && (

                <div className="h-full flex justify-center items-center">

                    {/* 診断結果ページへ遷移 */}
                    <button 
                    onClick={handleConfirmButtonClick}
                    className="bg-main-color rounded-lg w-72 h-16 p-4"
                    >
                        診断結果を確認する
                    </button>

                </div>

            )}

        </>
        </MainStyle>
    );
};


// Redux ストアからデータを取得し、コンポーネントのプロパティにマッピングします
const mapStateToProps = (state) => {
    return {
        // Reduxの selectedOptions ステートを selectedOptions プロパティにマッピング
        selectedOptions: state.selectedOptions,

        // Reduxの questionData ステートを questionData プロパティにマッピング
        questionData: state.questionData,
    };
};

// コンポーネント内で Redux アクションを呼び出すためのアクション関数をプロパティにマッピングします
const mapDispatchToProps = {
    // setSelectedOptions アクション関数を setSelectedOptions プロパティにマッピング
    setSelectedOptions,

    // setQuestionData アクション関数を setQuestionData プロパティにマッピング
    setQuestionData,
}


export default connect(mapStateToProps, mapDispatchToProps)(Question);
