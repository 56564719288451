import React from 'react';

const ProgressIndicator = ({ totalQuestions, currentQuestion }) => {

// 残りの質問数を計算
const remainingQuestions = totalQuestions - currentQuestion;

// 質問の進行率を計算
const completionPercentage = (currentQuestion / totalQuestions) * 100;

return (
    <div>
        <p className="remaining-text">{`終了まであと ${remainingQuestions} 問`}</p>

        <div className="progress-bar">
        <div
            className="progress-bar-fill"
            style={{ width: `${completionPercentage}%` }}
        />
        </div>

        <p className="text-right">{currentQuestion} / {totalQuestions}</p>
    </div>
);
};

export default ProgressIndicator;
