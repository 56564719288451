import React from 'react';

/*
親コンポーネントから受け取る値
- option: 選択肢の情報を受け取るプロパティ
- selected: 選択された選択肢のIDを保持するプロパティ
- handleOptionSelect: 選択肢が変更されたときに呼び出されるコールバック関数
*/
const QuestionOption = ({ option, selected, handleOptionSelect }) => {
    const handleCheckboxClick = (event) => {
        // イベントの伝播を止める
        event.stopPropagation();
        // チェックボックスをクリックした場合の処理
        handleOptionSelect(option.id);
    };

    return (
        <label className="flex flex-col items-center text-center space-x-2 my-10 w-1/5">
            <input
                type="radio"
                value={option.id}
                checked={selected === option.id}
                onChange={() => {}} // 空のonChangeを追加
                className="hidden"
            />

            <div className="relative">
                <div className="checkBox w-12 h-12 border border-gray-400 rounded shadow-lg flex items-center justify-center gap-3" onClick={handleCheckboxClick}>
                    <p className="font-medium text-slate-300">&#10004;</p>
                </div>
            </div>

            <p className="m-0">{option.text}</p>
        </label>
    );
};

export default QuestionOption;