import { useNavigate, useLocation } from 'react-router-dom';
// components
import { MainStyle } from '../components/MainStyle';

    const ResultConfirm = () => {

    const navigate = useNavigate();

    // 前ページから渡されたステートを受け取る
    const location = useLocation();

    const { state } = location;

    const selectedOptions = state.selectedOptions;

    console.log("aaaa",state);

    function handleButtonClick() {

        // ナビゲーションを実行
        navigate("/question/result", { selectedOptions: selectedOptions });

    }

    return (
        <MainStyle>

        <div className='flex h-full justify-center items-center'>

            {/* 診断結果に進む */}
            <button 
                onClick={handleButtonClick}
                className="bg-main-color rounded-lg w-72 h-16 p-4 flex justify-center items-center"
            >
                診断結果を確認する
            </button>

        </div>

        </MainStyle>
    );
};

export default ResultConfirm;
